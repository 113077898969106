import React from "react";

function WelcomeBanner() {
  return (
    <div
      className="relative p-4 overflow-hidden rounded-sm m sm:p-6"
      style={{ position: "relative" }}
    >
      {/* Image background */}
      <img
        src="images/GMS BG.webp"
        alt="Background Image"
        className="absolute inset-0 object-cover w-full h-full mb-1 rounded-xl"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />

      {/* Content with text overlay */}
      <div className="relative">
        <h1
          className="p-4 mb-1 font-sans text-3xl font-bold text-center text-black rounded-lg shadow-xs md:text-3xl bg-opacity-90 backdrop-filter backdrop-blur-lg"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
        >
          LoRa ENABLED GENSET MONITORING SYSTEM
        </h1>
        {/* Additional content can be placed here */}
      </div>
    </div>

    // <div className="relative p-4 overflow-hidden rounded-sm m sm:p-6">
    //   {/* Content */}

    //   <div className="relative">
    //     <h1 className="p-4 mb-1 font-sans text-2xl font-bold text-center text-black bg-white bg-opacity-50 rounded-lg shadow-xs md:text-3xl backdrop-filter backdrop-blur-lg">
    //       AUTOMATIC SOLAR PANEL CLEANER
    //     </h1>

    //   </div>

    // </div>
  );
}

export default WelcomeBanner;
